import React, { useState, useEffect, useCallback } from "react";
import { Grid, Box, Paper, Typography } from '@mui/material';
import EmailFormVerification from "../emailFormVerification/emailFormVerification";
import { verifyEmailAccess } from "../../utils/verifyEmailAccess/verifyEmailAccess";
const pageTitle = 'FMH Minor Proxy Form';
import SectionOneForm from "./minorPageOne";
import SectionTwoForm from "./minorPageTwo";
import SectionThreeForm from "./minorPageThree";
import SuccessPage from './minorPageFourSuccess';

import { useNavigate, useParams } from "react-router-dom";

import envConfig from "../../utils/configEnv/config";

const formSubject = "FMH Adult Proxy Form Request";
const configBaseUrl = envConfig?.REACT_APP_LOGICAPPSHAREPOINTURL;
const nwcypt = envConfig?.REACT_APP_NWCYPT;
const DPXAppHeader = "Bearer " + envConfig?.REACT_APP_DPX_APPKEY;

const iformHeaders = new Headers();
  iformHeaders.append("Authorization", DPXAppHeader);
  iformHeaders.append("Content-Type", "application/json");

export default function minorProxyFormNoGig() {
  let { id } = useParams();
  const navigate = useNavigate();

  const [showEmailForm, setShowEmailForm] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showPageOne, setShowPageOne] = useState(false);
  const [showPageTwo, setShowPageTwo] = useState(false);
  const [showPageThree, setShowPageThree] = useState(false);
  const [showPageFourSuccess, setShowPageFourSuccess] = useState(false);
  const [postResult, setPostResult] = useState(null);

  const updateProgress = useCallback(() => {
    const progressLeft = document.getElementsByClassName("progress-indicator-left")[0];
    const progressRight = document.getElementsByClassName("progress-indicator-right")[0];
    if (progressLeft && progressRight) {
      progressLeft.style.width = "90%";
      progressRight.style.width = "10%";
    }
  }, []);

  useEffect(() => {
    document.title = pageTitle;
    document.getElementById("pageTextHeader").innerHTML = pageTitle;

    if (id) {
      verifyEmailAccess(id).then(isVerified => {
        if (isVerified) {
          setShowEmailForm(false);
          setShowPageOne(true);
        } else {
          setShowEmailForm(false);
          setShowPageOne(false);
          setShowError(true);
        }
      });
    } else {
      // console.log('no id');
    }
    const progressLeft = document.querySelector(".progress-indicator-left");
    const progressRight = document.querySelector(".progress-indicator-right");
    if (progressLeft) progressLeft.style.width = "90%";
    if (progressRight) progressRight.style.width = "10%";

    scrollToTop();
    updateProgress();
  }, [id, updateProgress]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const postData = async (data) => {
    const formatDateString = (dateString) => {
      const [month, day, year] = dateString.split("/");
      return { month, day, year, formatted: `${month}-${day}-${year}` };
    };

    const patientDOB = formatDateString(data.sectionOneForm.DOBChild);
    const guardianDOB = formatDateString(data.sectionTwoForm.DOBGuardian);

    const postData = {
      firstName: data.sectionOneForm.firstName,
      lastName: data.sectionOneForm.lastName,
      mInitial: data.sectionOneForm.mInitial,
      date: patientDOB.formatted,
      dobMonth: patientDOB.month,
      dobDayofMonth: patientDOB.day,
      dobYear: patientDOB.year,
      gender: data.sectionOneForm.gender,
      streetNumber: data.sectionOneForm.streetNumber,
      City: data.sectionOneForm.City,
      State: data.sectionOneForm.State,
      Zip: data.sectionOneForm.Zip,
      relationshiptoPatient: data.sectionOneForm.relationshiptoPatient,
      firstNameGuardian: data.sectionTwoForm.firstNameGuardian,
      lastNameGuardian: data.sectionTwoForm.lastNameGuardian,
      mInitialGuardian: data.sectionTwoForm.mInitialGuardian,
      dobGuardian: guardianDOB.formatted,
      dobMonthGuardian: guardianDOB.month,
      dobDayofMonthGuardian: guardianDOB.day,
      dobYearGuardian: guardianDOB.year,
      streetNumberGuardian: data.sectionTwoForm.streetNumberGuardian,
      CityGuardian: data.sectionTwoForm.CityGuardian,
      StateGuardian: data.sectionTwoForm.StateGuardian,
      ZipGuardian: data.sectionTwoForm.ZipGuardian,
      phoneNumberGuardian: data.sectionTwoForm.phoneNumberGuardian,
      emailguardian: data.sectionTwoForm.emailguardian,
      uploadFile: data.sectionTwoForm.uploadFiles,
      signedImage: data.sectionThreeForm.imageUrl,
      accessKey: nwcypt,
      emailSubject: formSubject,
      timestamp: data.SectionTwoFormTimestamp,
    };

    try {
      const res = await fetch(configBaseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": "token-value",
        },
        body: JSON.stringify(postData),
      });

      if (!res.ok) {
        navigate(`/Error/FMH Submission Error ${res.statusText}`);
        return;
      }

      const data = await res.json();
      setPostResult({
        status: `${res.status}-${res.statusText}`,
        headers: {
          "Content-Type": res.headers.get("Content-Type"),
          "Content-Length": res.headers.get("Content-Length"),
        },
        data,
      });
    } catch (err) {
      setPostResult(err.message);
    }
  };

  const [ProxyFormObj] = useState({
    showPageThree: [],
    sectionOneForm: [],
    sectionTwoForm: [],
    SectionTwoFormTimestamp: "",
    showPageThreeSubmitted: false,
    sectionOneSubmitted: false,
    sectionTwoSubmitted: false,
    submitCompletedForm: false,
  });

  const formSubmission = useCallback(() => {
    const progressLeft = document.querySelector(".progress-indicator-left");
    const progressRight = document.querySelector(".progress-indicator-right");

    if (ProxyFormObj.goBackLogin) {
      if (progressLeft) progressLeft.style.width = "0%";
      if (progressRight) progressRight.style.width = "100%";
      navigate("/");
    }

    if (ProxyFormObj.goBackPageOne) {
      setShowPageOne(true);
      setShowPageTwo(false);
      setShowPageThree(false);
      ProxyFormObj.sectionOneSubmitted = true;
      ProxyFormObj.sectionTwoSubmitted = false;
      ProxyFormObj.submitCompletedForm = false;
      if (progressLeft) progressLeft.style.width = "80%";
      if (progressRight) progressRight.style.width = "20%";
    }

    if (ProxyFormObj.goBackPageTwo) {
      setShowPageOne(false);
      setShowPageTwo(true);
      setShowPageThree(false);
      if (progressLeft) progressLeft.style.width = "40%";
      if (progressRight) progressRight.style.width = "60%";
    }

    if (ProxyFormObj.goPageThree) {
      setShowPageOne(false);
      setShowPageTwo(false);
      setShowPageThree(true);
      if (progressLeft) progressLeft.style.width = "20%";
      if (progressRight) progressRight.style.width = "80%";
    }

    if (ProxyFormObj.showPageThreeSubmitted) {
      setShowPageOne(true);
      setShowPageTwo(false);
      setShowPageThree(false);
      if (progressLeft) progressLeft.style.width = "0%";
      if (progressRight) progressRight.style.width = "100%";
    }

    if (ProxyFormObj.submitCompletedForm) {
      setShowPageThree(false);
      postData(ProxyFormObj);
      scrollToTop();
      if (progressLeft) progressLeft.style.width = "100%";
      if (progressRight) progressRight.style.width = "0%";
      setShowPageFourSuccess(true);
    }
  }, [ProxyFormObj]);

  return (
    <div id="PageTop">
      {showEmailForm && <EmailFormVerification />}
      {showPageOne && <SectionOneForm SubmissionState={ProxyFormObj} onFormStateChange={formSubmission} />}
      {showPageTwo && <SectionTwoForm SubmissionState={ProxyFormObj} onFormStateChange={formSubmission} />}
      {showPageThree && <SectionThreeForm SubmissionState={ProxyFormObj} onFormStateChange={formSubmission} />}
      {showPageFourSuccess && <SuccessPage />}
      {showError && (
        <Box className="pageContainer">
          <Grid container direction="column" justifyContent="space-between" alignItems="left">
            <Grid item>
              <Typography variant="h1" style={{ marginTop: 80, marginBottom: 24 }}>
                Error State
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}
