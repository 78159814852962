import React, { useEffect } from "react";
import { Grid, Box, Paper, Typography } from '@mui/material'
const progressLeft = document.getElementsByClassName("progress-indicator-left");
const progressRight = document.getElementsByClassName("progress-indicator-right");
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  height: '24px',
  paddingRight: '12px',
  lineHeight: '24px',
}));

const scrolltoTop = function() {
    window.scrollTo(0, 0);
};

export default function EmailNotification() {
  let { email } = useParams();

  useEffect(() => {
    progressLeft[0].style.width = "90%";
    progressRight[0].style.width="10%";
    scrolltoTop();
  }, []);
   
    return ( 
    <Box className="pageContainer">
      <Grid container
        direction="column"
        justifyContent="space-between"
        alignItems="left">
        <Grid item xs={12}>
          <Typography variant="h1" style={{ marginBottom: 24, marginTop: 80 }}>Verify your email</Typography>
        </Grid>
        <Grid item xs={12}>
          <Item elevation={0}>
          <Typography variant="p" style={{ fontSize: 20 }}>
            An email has been sent to this <strong>{email}</strong>. Please verify your email address, by clicking the button "Verify email" in the email to continue.
          </Typography>
          </Item>
        </Grid>
      </Grid>
      </Box>
      );
    }
