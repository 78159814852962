import React, { useState, useEffect } from "react";
import styled from '@emotion/styled'
import "./PHQ2.css";
import { useForm, Controller} from "react-hook-form";
import { Grid, Box, Paper, Typography, Radio, FormControlLabel, FormControl, RadioGroup, FormLabel } from '@mui/material';
import { Button } from '@northwell-health/nw-component-lib';
import InformationPage from "./information";
import ResultsPage from "./results";

const progressLeft = document.getElementsByClassName("progress-indicator-left");
const progressRight = document.getElementsByClassName("progress-indicator-right");
const pageTitle = 'PHQ2';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  marginBottom: '24px',
  color: theme.palette.text.secondary
}));

export default function MentalHealthScreener() {
  
  useEffect(() => {
    document.querySelector('title').textContent = pageTitle;    
    document.getElementById("pageTextHeader").innerHTML = pageTitle;
    progressLeft[0].style.width = "90%";
    progressRight[0].style.width="10%";
  }, []);

  const [informationPageShow, showInfoPage] = useState(true);
  const [PHQPageShow, showPHQPage] = useState(false);
  const [resultsPageShow, showResultsPage] = useState(false);
  const [ phqScore ] = useState([{
    result: []
  }]);
  const startPHQ = function(){ 
    showInfoPage(false);
    showPHQPage(true);
    showResultsPage(false);
  };

  const PHQResults = function(data){ 
    showInfoPage(false);
    showPHQPage(false);
    showResultsPage(true);
    phqScore.result = data;
   
    //alert('Result Score '+ (parseInt(data.questionOne) + parseInt(data.questionTwo)));
  };

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      questionOne: '',
      questionTwo: '',
    },
  });

  const surveyQuestions = {
    "questions":[
      "Little interest or pleasure in doing things:",
      "Feeling down, depressed or hopeless:"
  ],
    "answerOne": {
      "textValue": "Not at All",
      "numValue": 0
    },
    "answerTwo": {
      "textValue": "Several days ",
      "numValue": 1
    },
    "answerThree": {
      "textValue": "More than half the days",
      "numValue": 2
    },
    "answerFour": {
      "textValue": "Nearly every day",
      "numValue": 3
    }
  };

  const onSubmit = (data) => PHQResults(data);

  return (
    <div>
    {informationPageShow ?<InformationPage onFormStateChange={startPHQ}/> :null}
    {resultsPageShow ?  <ResultsPage results={phqScore}/> :null}
    {PHQPageShow?
  <form name="PHQFORM" onSubmit={handleSubmit(onSubmit)}>
  <Box className="pageContainer">
    <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h1" style={{ marginTop: 80 }}>PHQ2</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography sx={{fontSize:23}} style={{  }}>
            Over the last 2 weeks, how often have you been bothered by the following problems?
            </Typography>
        </Grid>
        <Grid item xs={12}>
          <Item>
             <FormControl component="fieldset">
      <FormLabel sx={{fontSize: 22}} component="legend">{surveyQuestions.questions[0]}</FormLabel>
    <Controller
            rules={{ required: true }}
            control={control}
            name="questionOne"
            render={({ field }) => {
              // console.log(field)
              return (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value={surveyQuestions.answerOne.numValue}
                    control={<Radio />}
                    label={surveyQuestions.answerOne.textValue}
                  />
                  <FormControlLabel
                    value={surveyQuestions.answerTwo.numValue}
                    control={<Radio />}
                    label={surveyQuestions.answerTwo.textValue}
                  />
                  <FormControlLabel
                    value={surveyQuestions.answerThree.numValue}
                    control={<Radio />}
                    label={surveyQuestions.answerThree.textValue}
                  />
                   <FormControlLabel
                    value={surveyQuestions.answerFour.numValue}
                    control={<Radio />}
                    label={surveyQuestions.answerFour.textValue}
                  />
                </RadioGroup>
              );
            }}
          />
        </FormControl>
        </Item>
        </Grid>

        <Grid item xs={12}>
          <Item>   
        <FormControl component="fieldset">
      <FormLabel sx={{fontSize: 22}} component="legend">{surveyQuestions.questions[1]}</FormLabel>
    <Controller
            rules={{ required: true }}
            control={control}
            name="questionTwo"
            render={({ field }) => {
              // console.log(field)
              return (
                <RadioGroup row {...field}>
                   <FormControlLabel
                    value={surveyQuestions.answerOne.numValue}
                    control={<Radio />}
                    label={surveyQuestions.answerOne.textValue}
                  />
                  <FormControlLabel
                    value={surveyQuestions.answerTwo.numValue}
                    control={<Radio />}
                    label={surveyQuestions.answerTwo.textValue}
                  />
                  <FormControlLabel
                    value={surveyQuestions.answerThree.numValue}
                    control={<Radio />}
                    label={surveyQuestions.answerThree.textValue}
                  />
                   <FormControlLabel
                    value={surveyQuestions.answerFour.numValue}
                    control={<Radio />}
                    label={surveyQuestions.answerFour.textValue}
                  />
                </RadioGroup>
              );
            }}
          />
        </FormControl>
        </Item>
        </Grid>
        <Grid item xs={12}> 
        {/* {errors?.type === "required" && <p className="red">This field is required</p>} */}
        </Grid>
        <Grid item xs={12} s={2} md={2}>
          <Button className="noButtonMargin"
           text="Submit" color="primary" type="submit" />
          </Grid>
          <Grid item xs={12} s={2} md={2}>
            <Button text="Reset" color="primary" onClick={() => reset()} />
            </Grid>
           </Grid>
         </Box>
         </form>:null}
         </div> 
      );
    };
  