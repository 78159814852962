import { useParams } from 'react-router-dom';
import React, { useEffect } from "react";
import { Grid, Box, Typography} from '@mui/material'
const pageTitle = 'Error Page';

export default function ErrorPage() {
  let { error } = useParams();

  const progressLeft = document.getElementsByClassName("progress-indicator-left");
  const progressRight = document.getElementsByClassName("progress-indicator-right");
  const errorMessage = decodeURI(error);

  useEffect(() => {
    document.querySelector('title').textContent = pageTitle;    
    document.getElementById("pageTextHeader").innerHTML = pageTitle;
    progressLeft[0].style.width = "90%";
    progressRight[0].style.width="10%";
  }, []);

  return (
    <Box className="pageContainer">
      <Grid container
      direction="column"
      justifyContent="space-between"
      alignItems="left">
        <Grid xs={12}>
          <Typography variant="h1" style={{ marginTop: 80 , marginBottom: 24 }} className="imgAlign noPad">
          Need Help?
          </Typography>
        </Grid>
        <Grid item xs={12} s={12} md={12}>
          <Typography variant="p" style={{ fontSize: 20 }} >
          Give us a call weekdays from 8am to 8pm and weekends from 9am-5pm 
          at <bold>888-321-DOCS</bold>, and we'll be happy to assist you.
          <br/><br/>
          Check out our answers to <a href="https://www.northwell.edu/help">frequently asked questions</a>
          <br/><br/>
          </Typography>
          <Typography variant="h4" style={{ marginBottom: 10  }} className="imgAlign noPad">
          Error Code / Description:
          </Typography>
          <Typography variant="p" style={{ fontSize: 20 }} >
          <br/>{errorMessage}
          </Typography>
        </Grid>
      </Grid>
    </Box>
    );
  };
  