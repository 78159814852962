import React from "react";
import { Grid, Box, Typography} from '@mui/material'

const highScore = ( ) => {
  return (
    <Grid xs={12}>
          <Typography variant="p" style={{ fontSize: 20 }} >        
            Your screening may indicate depression, please make an appointment to see your primary care doctor.
            <br/>
             If you don't have one call/click for a referral (888) 321-DOCS <a href="https://www.northwell.edu/find-care" target="new">
          https://www.northwell.edu/find-care</a>
         <br/> <br/>
         <a href="https://www.nimh.nih.gov/sites/default/files/documents/health/publications/depression/21-mh-8079-depression_0.pdf"
         target="new">
            https://www.nimh.nih.gov/sites/default/files/documents/health/publications/depression/21-mh-8079-depression_0.pdf
        </a>
         <br/><br/>
         Call 1-800-273-TALK (1-800-273-8255)
         <br/><br/>
         <a href="https://suicidepreventionlifeline.org"
         target="new">suicidepreventionlifeline.org</a>
          <br/><br/>
         You can also connect 24/7 to a crisis counselor by texting the <u>Crisis Text Line</u>. Text HOME to 741741.
          </Typography>             
        </Grid>
    );
  };
  export default highScore;
