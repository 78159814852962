import React from 'react'

export default function northwellheader(props) {
    const { formHeader } = props;
    return (
    <div className="blue-bar headerflex">
      <div className="internal-container" id="pageTextHeader">{formHeader}</div>
    </div>
    );
  }
  