import React, { useState, useEffect } from "react";
import { Grid, Box, Typography} from '@mui/material'
const pageTitle = 'Results Page';
import LowScore from "./lowScore";
import HighScore from "./highScore";

let score = null;

const ResultsPage = ( props ) => {
  const progressLeft = document.getElementsByClassName("progress-indicator-left");
  const progressRight = document.getElementsByClassName("progress-indicator-right");
  const { results } = props;
  const [resultHighScore, showHigh] = useState(false);
  const [resultLowScore, showLow] = useState(false);

  const calcuateScore = function (score) {
    score = (parseInt(score.questionOne) + parseInt(score.questionTwo));
    if (score >= 3) {
      // Show High Score Result
      showHigh(true);
      // console.log("high" + score);
    } else if (score < 3) {
      // Show Low Score Resul
      showLow(true);
      // console.log("low" + score );
    }
  }

  useEffect(() => {
    document.querySelector('title').textContent = pageTitle;    
    document.getElementById("pageTextHeader").innerHTML = pageTitle;
    progressLeft[0].style.width = "90%";
    progressRight[0].style.width="10%";
    calcuateScore(results.result);
  }, []);

  return (
    <Box className="pageContainer">
      <Grid container
      direction="column"
      justifyContent="space-between"
      alignItems="left">
        <Grid xs={12}>
          <Typography variant="h1" style={{ marginTop: 80 , marginBottom: 24 }} className="imgAlign noPad">
          Thank you for completing your depression screening.</Typography>
        </Grid>
        {resultHighScore ?
        <HighScore />:null}
        {resultLowScore ?
        <LowScore />:null}
      </Grid>
    </Box>
    );
  };
  export default ResultsPage;
