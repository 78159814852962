import React from 'react'

export default function northwellLog() {
    return (
    <div className="northwell-head-container">
      <div className="header-container">
        <img className="centerImage" src="/images/NorthwellHealthLogo.svg"/>
        </div>
    </div>
    );
  }
  