import React, { useState, useEffect, useCallback } from "react";
import SectionOneForm from "./minorPageOne";
import SectionTwoForm from "./minorPageTwo";
import SectionThreeForm from "./minorPageThree";
import SuccessPage from "./minorPageFourSuccess";

import { useNavigate, useParams } from "react-router-dom";

import envConfig from "../../utils/configEnv/config";

const formSubject = "FMH Minor Proxy Form Request";
const configBaseUrl = envConfig.REACT_APP_LOGICAPPSHAREPOINTURL;
const nwcypt = envConfig.REACT_APP_NWCYPT;
const dpxURL = `https://${envConfig.REACT_APP_DPXURL}`;
const getAccountInfoURL = `${dpxURL}${envConfig.REACT_APP_DPXACCOUNTINFOURL}`;
const DPXAppHeader = `Bearer ${envConfig.REACT_APP_DPX_APPKEY}`;

const iformHeaders = new Headers({
  Authorization: DPXAppHeader,
  "Content-Type": "application/json",
});

async function handleGigyaGetAccountInfoFromURL(gigLiToken) {
  const rawPayload = JSON.stringify({ urlParam: gigLiToken });


  try {
    const response = await fetch(getAccountInfoURL, {
      method: "POST",
      headers: iformHeaders,
      body: rawPayload,
    });

    if (response.ok) {
      const data = await response.json();
      if (data.postValidateGigyaLiteAccountResponse.isActive) {
        // Handle active account
      } else {
        navigate(`/Error/Invalid or Expired Token ${data.postValidateGigyaLiteAccountResponse.statusCode}`);
      }
    } else {
      navigate(`/Error/Expired or Invalid Token`);
    }
  } catch (error) {
    navigate(`/Error/Invalid or Expired Token ${error}`);
  }
}

export default function FormContainer() {

  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    if (id) {
      handleGigyaGetAccountInfoFromURL(id);
    } else {
      navigate("/Error/Invalid Token");
    }
    const progressLeft = document.querySelector(".progress-indicator-left");
    const progressRight = document.querySelector(".progress-indicator-right");
    if (progressLeft) progressLeft.style.width = "90%";
    if (progressRight) progressRight.style.width = "10%";
    scrollToTop();
  }, [id]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [showPageOne, setShowPageOne] = useState(true);
  const [showPageTwo, setShowPageTwo] = useState(false);
  const [showPageThree, setShowPageThree] = useState(false);
  const [showPageFourSuccess, setShowPageFourSuccess] = useState(false);
  const [postResult, setPostResult] = useState(null);

  const postData = async (data) => {
    const formatDateString = (dateString) => {
      const [month, day, year] = dateString.split("/");
      return { month, day, year, formatted: `${month}-${day}-${year}` };
    };

    const patientDOB = formatDateString(data.sectionOneForm.DOBChild);
    const guardianDOB = formatDateString(data.sectionTwoForm.DOBGuardian);

    const postData = {
      firstName: data.sectionOneForm.firstName,
      lastName: data.sectionOneForm.lastName,
      mInitial: data.sectionOneForm.mInitial,
      date: patientDOB.formatted,
      dobMonth: patientDOB.month,
      dobDayofMonth: patientDOB.day,
      dobYear: patientDOB.year,
      gender: data.sectionOneForm.gender,
      streetNumber: data.sectionOneForm.streetNumber,
      City: data.sectionOneForm.City,
      State: data.sectionOneForm.State,
      Zip: data.sectionOneForm.Zip,
      relationshiptoPatient: data.sectionOneForm.relationshiptoPatient,
      firstNameGuardian: data.sectionTwoForm.firstNameGuardian,
      lastNameGuardian: data.sectionTwoForm.lastNameGuardian,
      mInitialGuardian: data.sectionTwoForm.mInitialGuardian,
      dobGuardian: guardianDOB.formatted,
      dobMonthGuardian: guardianDOB.month,
      dobDayofMonthGuardian: guardianDOB.day,
      dobYearGuardian: guardianDOB.year,
      streetNumberGuardian: data.sectionTwoForm.streetNumberGuardian,
      CityGuardian: data.sectionTwoForm.CityGuardian,
      StateGuardian: data.sectionTwoForm.StateGuardian,
      ZipGuardian: data.sectionTwoForm.ZipGuardian,
      phoneNumberGuardian: data.sectionTwoForm.phoneNumberGuardian,
      emailguardian: data.sectionTwoForm.emailguardian,
      uploadFile: data.sectionTwoForm.uploadFiles,
      signedImage: data.sectionThreeForm.imageUrl,
      accessKey: nwcypt,
      emailSubject: formSubject,
      timestamp: data.SectionTwoFormTimestamp,
    };

    try {
      const res = await fetch(configBaseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": "token-value",
        },
        body: JSON.stringify(postData),
      });

      if (!res.ok) {
        navigate(`/Error/FMH Submission Error ${res.statusText}`);
        return;
      }

      const data = await res.json();
      setPostResult({
        status: `${res.status}-${res.statusText}`,
        headers: {
          "Content-Type": res.headers.get("Content-Type"),
          "Content-Length": res.headers.get("Content-Length"),
        },
        data,
      });
    } catch (err) {
      setPostResult(err.message);
    }
  };

  const [ProxyFormObj] = useState({
    showPageThree: [],
    sectionOneForm: [],
    sectionTwoForm: [],
    SectionTwoFormTimestamp: "",
    showPageThreeSubmitted: false,
    sectionOneSubmitted: false,
    sectionTwoSubmitted: false,
    submitCompletedForm: false,
  });

  const formSubmission = useCallback(() => {
    const progressLeft = document.querySelector(".progress-indicator-left");
    const progressRight = document.querySelector(".progress-indicator-right");

    if (ProxyFormObj.goBackLogin) {
      if (progressLeft) progressLeft.style.width = "0%";
      if (progressRight) progressRight.style.width = "100%";
      navigate("/");
    }

    if (ProxyFormObj.goBackPageOne) {
      setShowPageOne(true);
      setShowPageTwo(false);
      setShowPageThree(false);
      ProxyFormObj.sectionOneSubmitted = true;
      ProxyFormObj.sectionTwoSubmitted = false;
      ProxyFormObj.submitCompletedForm = false;
      if (progressLeft) progressLeft.style.width = "80%";
      if (progressRight) progressRight.style.width = "20%";
    }

    if (ProxyFormObj.goBackPageTwo) {
      setShowPageOne(false);
      setShowPageTwo(true);
      setShowPageThree(false);
      if (progressLeft) progressLeft.style.width = "40%";
      if (progressRight) progressRight.style.width = "60%";
    }

    if (ProxyFormObj.goPageThree) {
      setShowPageOne(false);
      setShowPageTwo(false);
      setShowPageThree(true);
      if (progressLeft) progressLeft.style.width = "20%";
      if (progressRight) progressRight.style.width = "80%";
    }

    if (ProxyFormObj.showPageThreeSubmitted) {
      setShowPageOne(true);
      setShowPageTwo(false);
      setShowPageThree(false);
      if (progressLeft) progressLeft.style.width = "0%";
      if (progressRight) progressRight.style.width = "100%";
    }

    if (ProxyFormObj.submitCompletedForm) {
      setShowPageThree(false);
      postData(ProxyFormObj);
      scrollToTop();
      if (progressLeft) progressLeft.style.width = "100%";
      if (progressRight) progressRight.style.width = "0%";
      setShowPageFourSuccess(true);
    }
  }, [ProxyFormObj]);

  return (
    <div id="PageTop">
      {showPageOne && <SectionOneForm SubmissionState={ProxyFormObj} onFormStateChange={formSubmission} />}
      {showPageTwo && <SectionTwoForm SubmissionState={ProxyFormObj} onFormStateChange={formSubmission} />}
      {showPageThree && <SectionThreeForm SubmissionState={ProxyFormObj} onFormStateChange={formSubmission} />}
      {showPageFourSuccess && <SuccessPage />}
    </div>
  );
}
