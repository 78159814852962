import React from "react";
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import GigyaLogin from "../components/gigyalogin/gigyalogin";
import MinorProxyForm from "../components/minorProxyForm/minorProxyForm";
import EmailNotification from '../components/emailNotification/emailNotification';
import AdultProxyForm from "../components/adultproxyform/adultproxyform";
import MinorProxyFormNoGigya from "../components/minorProxyForm/minorFormNoGigya";
import PHQ2 from '../components/PHQ2/PHQ2';
import DynamicIforms from '../components/dynamicIforms/dyanmicIforms';
import ErrorPage from '../components/errorpage/errorPage';

const AppRoutes = () => (
  <Router basename="/">
    <Routes>
      <Route exact path="/" element={<GigyaLogin />} />
      <Route path="/minorProxyFormCont/:id" element={<MinorProxyForm />} />
      <Route exact path="/AdultProxyForm/:id?" element={<AdultProxyForm />} />
      <Route exact path="/MinorProxyForm/:id?" element={<MinorProxyFormNoGigya />} />
      <Route exact path="/PHQ2" element={<PHQ2 />} />
      <Route exact path="/EmailNotification/:email" element={<EmailNotification />} />
      <Route exact path="/Error/:error" element={<ErrorPage />} />
      <Route exact path="/dynamiciforms" element={<DynamicIforms />} />
    </Routes>
  </Router>
);

export default AppRoutes;
