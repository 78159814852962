import React, { useEffect } from "react";
import { Grid, Box, Typography} from '@mui/material'
const pageTitle = 'PHQ2 ';
import { Button } from '@northwell-health/nw-component-lib'

const InformationPage = ( props ) => {
    const { onFormStateChange } = props;
    const progressLeft = document.getElementsByClassName("progress-indicator-left");
    const progressRight = document.getElementsByClassName("progress-indicator-right");

    const nextPage = () => {  
        onFormStateChange();  
      };

  useEffect(() => {
    document.querySelector('title').textContent = pageTitle;    
    document.getElementById("pageTextHeader").innerHTML = pageTitle;
    progressLeft[0].style.width = "90%";
    progressRight[0].style.width="10%";
  }, []);

  return (
    <Box className="pageContainer">
      <Grid container
      direction="column"
      justifyContent="space-between"
      alignItems="left">
        <Grid xs={12}>
          <Typography variant="h1" style={{ marginTop: 80 , marginBottom: 24 }} className="imgAlign noPad">
         PHQ Information
          </Typography>       
          </Grid>
          <Grid xs={12}>
          <Typography variant="p" style={{ fontSize: 20 }} >
          Northwell stresses the importance of depression screening for all patients on an annual basis. We ask because we care!
          <br/>
          DISCLAIMER: Please be advised that your answers to this questionnaire will <b><u>not</u></b> be reviewed prior to your appointment. 
          Your responses will not be reviewed with you by your provider <b><u>at the time of your visit</u></b>.&nbsp;Should you need any mental health resources before your scheduled visit, 
          please refer to those provided for you during this survey or,
          if you are in need of emergent assistance, please go to 
          your closest Emergency Room or call 911
          <br/><br/>
          <a href="https://www.nimh.nih.gov/sites/default/files/documents/health/publications/depression/21-mh-8079-depression_0.pdf"
          target="new">
           https://www.nimh.nih.gov/sites/default/files/documents/health/publications/depression/21-mh-8079-depression_0.pdf
          </a><br/><br/>
          </Typography>         
        </Grid>
        <Grid xs={12}>
        <Button text="Continue" color="primary" type="submit"
          onClick={nextPage}
        />
        </Grid>        
      </Grid>
    </Box>
    );
  };
  export default InformationPage;