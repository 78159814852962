import React from "react";
import { Grid, Box, Typography} from '@mui/material'

const lowScore = ( ) => {
  return (
    <Grid xs={12}>
    <Typography variant="p" style={{ fontSize: 20 }} >
    We appreciate you taking the time to prioritize your health. We look forward to seeing you at your next visit.
      </Typography>
      </Grid>
    );
  };
  export default lowScore;
