import React, { useEffect } from "react";
import styled from '@emotion/styled'
import "./style.css";
import { useForm, Controller} from "react-hook-form";
import { Grid, Box, Paper, Typography, Radio, FormControlLabel, FormControl, RadioGroup, FormLabel } from '@mui/material';
import { Button } from '@northwell-health/nw-component-lib';
import dynamicIformMd from './dynamicIformModel';

const progressLeft = document.getElementsByClassName("progress-indicator-left");
const progressRight = document.getElementsByClassName("progress-indicator-right");
const pageTitle = 'PHQ2';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  marginBottom: '24px',
  color: theme.palette.text.secondary
}));

// *********** 
// *********** Begin surveyQuestionComponent
 // Protype of Component For Dynamic Forms 
 //Question Set Title and Survey Content content
// Outstanding Issues - 08/19/23
// Need to Setup Valuation - Probably just need to add HandleSubmit into runtime of component
// Need to add Translations
function SurveyQuestionComp(props) {

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      questionOne: '',
      questionTwo: '',
    },
  });


  const questionSet = (
    <Grid item xs={12}>
    <Typography sx={{fontSize:23}} style={{  }}>
      {props.questionset[0].questionSetTitle}
    </Typography>
    </Grid>
    );
  
  const content = props.surveyquestion.map((Questions) =>
  // Iterate through questions and assign a controler with questionLabel
  <Grid item xs={12} key={Questions.questionLabel}>
     <Item>
     <FormControl component="fieldset">
      <FormLabel sx={{fontSize: 22}} component="legend">
      {Questions.questionLabel}
      </FormLabel>
      <Controller
            rules={{ required: true }}
            control={control}
            name= {Questions.questionLabel}
            render={({ field }) => {
              // console.log(field)
              return (
                <RadioGroup row {...field}>
                {props.surveyanswer.map(surveyanswer => (
                <FormControlLabel
                key={surveyanswer.numValue}
                value={surveyanswer.numValue}
                control={<Radio />}
                label={surveyanswer.answer}
           />
        ))}        
        </RadioGroup>
              );
            }}
          />
        </FormControl>
      </Item>                                         
    </Grid>
  );
  return (
    <div>     
      {questionSet}
      {content} 
    </div>
  );
}
const Questions = dynamicIformMd.Questions;
const Answers = dynamicIformMd.Answers.questionGrp01.questions;
const QuestionTitle = dynamicIformMd.QuestionSet;

// *********** End surveyQuestionComponent
// *********** 


export default function MentalHealthScreener() {
  
  useEffect(() => {
    document.querySelector('title').textContent = pageTitle;    
    document.getElementById("pageTextHeader").innerHTML = pageTitle;
    progressLeft[0].style.width = "90%";
    progressRight[0].style.width="10%";
  }, []);

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      questionOne: '',
      questionTwo: '',
    },
  });

  const onSubmit = (data) => 
  alert('Result Score '+ (parseInt(data.questionOne) + parseInt(data.questionTwo)));

  return (
  <form name="PHQFORM" onSubmit={handleSubmit(onSubmit)}>
  <Box className="pageContainer">
    <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant="h1" style={{ marginTop: 80 }}>PHQ2</Typography>
        </Grid>
        <Grid item xs={12}>
          <SurveyQuestionComp 
              surveyquestion={Questions}
              surveyanswer={Answers}
              questionset={QuestionTitle}/>
        </Grid>

        <Grid item xs={12}> 
        {/* {errors?.type === "required" && <p className="red">This field is required</p>} */}
        </Grid>
        <Grid item xs={12} s={2} md={2}>
          <Button className="noButtonMargin"
           text="Submit" color="primary" type="submit" />
          </Grid>
          <Grid item xs={12} s={2} md={2}>
            <Button text="Reset" color="primary" onClick={() => reset()} />
            </Grid>
           </Grid>
         </Box>
         </form>
      );
    };
  