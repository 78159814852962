import React, { useState,useEffect } from "react";
import { useRecaptcha } from "react-hook-recaptcha";
import { useForm, Controller} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import envConfig from "../../utils/configEnv/config";
import { TextField, Button } from '@northwell-health/nw-component-lib';
import styled from '@emotion/styled';
import { Grid, Box, Paper, Typography } from '@mui/material'

const Item = styled(Paper)(({
  theme
}) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  minHeight: '65px',
  lineHeight: '60px',
  maxWidth: '400px',
  marginBottom: '24px'
}));

const isValidEmail = email =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

const containerId = "test-recaptcha";
const sitekey = envConfig?.REACT_APP_GOOGLERECAPTCHASITEKEY;
const progressLeft = document.getElementsByClassName("progress-indicator-left");
const progressRight = document.getElementsByClassName("progress-indicator-right");
const dpxURL = 'https://' + envConfig?.REACT_APP_DPXURL;
const gigyaSetAccountURL =  dpxURL + envConfig?.REACT_APP_DPXSETACCOUNTURL;
const gigyaSendInviteUrl = dpxURL + envConfig?.REACT_APP_DPXASENDINVITEURL;
const DPXAppHeader = "Bearer " + envConfig?.REACT_APP_DPX_APPKEY;
  
const iformHeaders = new Headers();
iformHeaders.append("Authorization", DPXAppHeader);
iformHeaders.append("Content-Type", "application/json");

export default function GigyaLogin() {

  const navigate = useNavigate();

  async function gigyaSetAccountInfo(formData) {
    let payload = JSON.stringify({
      "email": formData.email,
      "firstName": formData.fname,
      "lastName": formData.lname
    });

    try {
      const res = await fetch(gigyaSetAccountURL, {
        method: "post",
        headers: iformHeaders,
        body: payload,
      });

      if (!res.ok) {
        const errorMsg = 'Set Account Issue ' + res.statusText
        navigate(`/Error/${encodeURIComponent(errorMsg)}`);
        return {
          success: false,
          result: 'Set Account Issue ' + res.statusText
        };
      }

      const responseData = await res.json();
      gigyaSendInvite(formData, responseData.postCreateGigyaLiteAccountServiceResponse);
      const userEmail = formData.email;
      navigate(`/EmailNotification/${encodeURIComponent(userEmail)}`);
      return {
        success: true,
        result: responseData
      };
    } catch (err) {
      navigate('/Error/' + 'Account Creation Error ' + err);
      return {
        success: false,
        result: 'Account Creation Error ' + err
      };
    }
  };


  async function gigyaSendInvite(formData, postCreateGigyaLiteAccountServiceResponse) {
    let payload = JSON.stringify({
      "email": formData.email,
      "gigyaliteToken": postCreateGigyaLiteAccountServiceResponse
    });
    try {
      const res = await fetch(gigyaSendInviteUrl, {
        method: "post",
        headers: iformHeaders,
        body: payload,
      });
      if (!res.ok) {
        const errorMsg = 'Account Invite Error';
        navigate(`/Error/${encodeURIComponent(errorMsg)}`);
      }
      navigate('/EmailNotification/' + formData.email);
    } catch (err) {

      const errorMsg = 'Account Invite Error ' + err;
      navigate(`/Error/${encodeURIComponent(errorMsg)}`);
    }
  };

  useEffect(() => {
    progressLeft[0].style.width = "100%";
    progressRight[0].style.width = "0";

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const gigyaURLToken = urlParams.get('gig_regToken');
    const errorState = urlParams.get('error');
    const formId = urlParams.get('form_id');
    switch (formId) {
      case 'fmh_minorproxyform':        
        break;
      case 'PHQ2':
        navigate('/PHQ2');
        break;
      case 'adultproxyform':        
        navigate('/AdultProxyForm');
        break;
      case 'dynamiciforms':        
        navigate('/dynamiciforms');
        break;
    }

    if (gigyaURLToken) {
      navigate('/minorProxyFormCont/' + gigyaURLToken);
    }

    if (errorState) {
      navigate('/Error/' + "Invalid Or Expired Token");
    }
  }, [])

  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm();
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const handleEmailValidation = email => {
    const isValid = isValidEmail(email);
    return isValid;
  };

  const successCallback = (response) => {
    setCaptchaResponse(response);
  };

  const handleRegistration = (data) => {
    gigyaSetAccountInfo(data);
  };

  const expiredCallback = () => setCaptchaResponse(null);

  useRecaptcha({
    containerId,
    successCallback,
    expiredCallback,
    size: "normal",
    sitekey
  });

  return (
    <Box className="pageContainer">
    <Grid container
    direction="column"
    justifyContent="space-between"
    alignItems="left">
        <Grid item>
          <Typography variant="h1" style={{ marginTop: 80, marginBottom: 24 }}>Enter your details</Typography>
        </Grid>
      <form onSubmit={handleSubmit(handleRegistration)}>      
        <Grid item xs={12}>
          <Item elevation={0}>
          <Controller
          name="fname" 
          control={control} 
          render={({ field: { onChange } }) => 
          <TextField
           id="fname"
           autocomplete="given-name"
            onChange={onChange} 
            label="First name"             
            fullWidth
            required
            />} 
            rules={{ 
              required: true,
              pattern: /^[A-Z a-z]*$/i,
              }} />
            {errors?.fname?.type === "required" && <p className="red">This field is required</p>}
            {errors?.fname?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}                    
          </Item>
          </Grid>
          <Grid item xs={12}>
          <Item elevation={0}>
            <Controller 
              name="lname" 
              control={control} 
              autocomplete="family-name"
              render={({ field: { onChange } }) => 
          <TextField id="lname" onChange={onChange}
          fullWidth 
          label="Last name" required />}
          rules={{
             required: true,
             pattern: /^[A-Z a-z]*$/i,
              }} 
              />
            {errors?.lname?.type === "required" && <p className="red">This field is required</p>}
            {errors?.lname?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}
            </Item>
            </Grid>
            <Grid item xs={12}>
            <Item elevation={0}>
              <Controller 
              name="email" 
              control={control} 
              render={({ field: { onChange } }) => 
              <TextField id="email" 
              onChange={onChange} 
              autocomplete="off"
              label="Email address"
              fullWidth
              required />}
          rules={{
            required: true,
            pattern: /^[A-Z a-z0-9@.!#$%&'*+-/=?^_`{|}~]*$/i,
            maxLength: 122, validate: handleEmailValidation 
              }} />
            {errors?.email?.type === "required" && <p className="red">This field is required</p>}
            {errors?.email?.type === "validate" && <p className="red">Email is not in proper format</p>}
            {errors?.email?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}
            </Item>
            </Grid>
        <Grid item xs={12}>
        <Item elevation={0}>
        <div id={containerId} className="g-recaptcha" />
        <Button disabled={!captchaResponse} 
        text="Submit" color="primary" type="submit" />
        </Item>
        </Grid>
      </form>
      </Grid>
   </Box>
      );
}
